import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { AdsGETApi, AdsGETApiAuth, sideBarImage, signInImage } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';

export default function AllBooksDetails(props) {
  const { navigation } = props;
  // const { type, title } = props.route.params;
  const [AllExamList1, setAllExamList1] = React.useState({});
  const [AllExamList, setAllExamList] = React.useState([]);
  const [reload, setReload] = React.useState(true)
  const LoginState = useSelector((state) => state.LoginState);
  const [usersData, setUsersData] = React.useState({ meta: { number_of_exams: "0" } });
  const [AllExamListUnavailable, setAllExamListUnavailable] = React.useState([]);
  const [AllExamListUnavailable1, setAllExamListUnavailable1] = React.useState([]);
  const [VerifiedvExams, setVerifiedExam] = React.useState([]);
  const [FreeBooks, setFreeBooks] = React.useState([]);
  const location = useLocation()
  var type = location.pathname.slice(slugs.books.length + 1) == "" ? "ubt" : location.pathname.slice(slugs.all_exam.length + 1)
  const [PreviewPressed, setPreviewPressed] = React.useState(false)
  const [PreviewUrl, setPreviewUrl] = React.useState("")
  const [Chapter, setChapters] = React.useState([])
  const [PreviewType, setPreviewType] = React.useState("")
  const [PreviewObj, setPreviewObj] = React.useState({})
  const [PenModal, setPenModal] = React.useState(false)

  const groups = {
    "basic-tools": "Basic Tools",
    "topic-meaning": "Topic Meaning",
    "important-meaning": "Important Meaning",
    "grammar": "Grammer",
    "chapter-meaning": "Chapter Meaning",
    "text-book": "Text Book",
    "modal-question": "Modal Question"
  }

  const fetchExam = (perPage,) => {
    setReload(true)
    const date = new Date()
    AdsGETApiAuth({
      ...perPage,
    }, slugs.db_slug_book)
      .then((res) => {
        setReload(false)
        setAllExamList1(res.data[0])
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })
  }
  useEffect(() => {
    var type;
    type = location.pathname.slice(slugs.books_details.length + 1)
    fetchExam({
      per_page: 1000,
      order: "asc",
      _fields: "id,title,meta",
      slug: type
    }
    )
  }, [])

  return (
    <div>
    {/* {JSON.stringify(AllExamList1)} */}
    <div style={{ backgroundColor: "#eee", minHeight: "90vh" }}>
      <div>
        <div className="row">
          <div className="col-sm-1"></div>
          <div className="col-sm-10 form-view" >
            {!reload &&
              <section>
                <div>
                  <div style={{ textAlign: "center" }}>
                    {AllExamList1.meta.featured_image.includes("https://") ?
                      <img src={AllExamList1.meta.featured_image}
                        style={{ height: "100px" }}
                      />
                      :
                      <img src={sideBarImage}
                        style={{ height: "100px" }}
                      />
                    }
                  </div>
                  <div style={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}>
                    {AllExamList1.title.rendered}
                  </div>
                  <h2>Course Chapters</h2>
                  <div className="row" style={{ marginLeft: "10px" }}>
                    {Object.values(JSON.parse(AllExamList1.meta.course_content)).map((item, index) => (
                      <div key={index} className="col-sm-6 col-12">
                        <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "10px 10px 0 0 ", padding: "10px" }}
                          onClick={() => {
                            setPreviewPressed(true)
                            setPreviewObj(item)
                            setPreviewType(item.chapter_type)

                            if (item.chapter_type == "PDF")
                              setPreviewUrl(item.pdf_url)
                            else
                              setPreviewUrl(item.content)
                          }}
                        >
                          {/* {JSON.stringify(item)} */}

                          <h4>{item.title}</h4>
                          <div style={{ marginLeft: "40px", fontSize: "12px" }}>
                            <span>{item.description}</span>
                            {/* <span>[{item.chapter_type}]</span> */}
                            {/* <span>{JSON.parse(item.pdf_list).length !== 0 ? "[PDF]" : ""}</span>
                            <span>{JSON.parse(item.pdf_list).length !== 0 ? "[Audio]" : ""}</span> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                </div>
              </section>
            }
          </div>
        </div>
      </div>
    </div>
    {PreviewPressed &&
      <div className="modal">
        <div className="row" style={{ overflowX: "hidden" }}>
          <div class="modal-dialog" role="document" className="col-sm-12" style={{ overflowX: "hidden" }}>
            <div class="modal-content" style={{ overflowX: "hidden" }}>
              <div class="modal-body" style={{ overflowX: "hidden" }}>
                <div style={{ minHeight: "78vh", overflowY: "scroll", overflowX: "hidden" }}>
                  <div>
                    {Object.values(JSON.parse(PreviewObj.audio_list)).length !== 0 &&
                      <>
                        <div className="row">
                          <div className="col-sm-2 d-sm-none d-block" style={{ textAlign: "right" }}>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                              onClick={() => { setPreviewPressed(false) }}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="col-sm-9" style={{ whiteSpace: "nowrap", overflowX: "scroll" }}>
                            <div style={{ overflow: "auto", display: "inline", }}>
                              {Object.values(JSON.parse(PreviewObj.audio_list)).map((item, index) => (
                                <div key={index} style={{ display: "inline-block" }}>
                                  {/* {item.audio_url} */}
                                  {item.audio_url.includes("https") &&
                                    <div className="shadow" style={{ cursor: "pointer", backgroundColor: "#fff", margin: "5px", padding: "6px", borderRadius: 10 }}
                                      onClick={() => {
                                        // setAudioUrl(item)
                                      }}
                                    >
                                      <center>
                                        <div onClick={() => { }}>
                                          <AudioComp media={item.audio_url} />
                                          {/* <audio controls autoplay style={{ width: "90px" }}>
                                            <source src={`${item.audio_url}`} type="audio/mpeg" />
                                            <source src={`${item.audio_url}`} type="audio/mp3" />
                                            <source src={`${item.audio_url}`} type="audio/wav" />
                                            <source src={`${item.audio_url}`} type="audio/ogg" />
                                            Your browser does not support the audio element.
                                          </audio> */}
                                        </div>
                                        <div style={{ fontSize: "8px" }}>
                                          {item.audio_url.slice(51).replace(".mp3", "")}
                                        </div>
                                      </center>
                                    </div>
                                  }
                                </div>
                              ))}

                            </div>
                          </div>
                          <div className="col-sm-2 d-sm-block d-none" style={{ textAlign: "right" }}>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                              onClick={() => { setPreviewPressed(false) }}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>

                        </div>
                      </>
                    }
                  </div>
                  {PreviewType == "PDF" &&
                    // <iframe
                    //   style={{ width: "100%", height: "80vh" }}
                    //   src={PreviewUrl}></iframe>
                    // <embed style={{ width: "100%", height: "85vh" }}
                    //   src={PreviewUrl}
                    // //  height="100%" width="100%" 
                    // />
                    // <object
                    // style={{ width: "100%", height: "85vh" }}
                    // data={`https://drive.google.com/viewerng/viewer?embedded=true&url=${PreviewUrl}`} type="application/pdf">
                    //   <embed src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${PreviewUrl}`}  type='application/pdf'
                    //    style={{ width: "100%", height: "85vh" }}
                    //   />
                    // </object>
                    <div>
                     
                      {/* <div className="modal" style={{ padding: "50vw" }}>
                        <div className="dot-pulse-2"></div>
                      </div> */}
                      <iframe style={{ width: "90vw", height: "85vh" ,position:"absolute",zIndex:1}}

                        src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${PreviewUrl}`} />
                         <div >
                      </div>
                      <div style={{ padding: "37vh 50vw", backgroundColor: "#eee" }}>
                          <div className="dot-pulse-2"></div>
                        </div>
                    </div>
                  }

                  {/* {PenModal &&
                    <div className="modal2" style={{height:"82vh",overflowY:"hidden",border:"1px solid #bbb"}}>
                      <ReactSketchCanvas
                        // style={styles}
                        width="100vw"
                        height="70vh"
                        strokeWidth={4}
                        strokeColor="red"
                        canvasColor={"#fffff003"}
                      />
                      <div onClick={() => { setPenModal(!PenModal) }}>
                        Clear
                      </div>
                    </div>
                  }
                  <div onClick={() => { setPenModal(!PenModal) }}>
                    Pen
                  </div> */}

                  {PreviewType == "Text" &&
                    <div className="shadow" style={{ height: "70vh", backgroundColor: "#fff", margin: "5px", padding: "10px", overflowY: "scroll" }}>
                      {PreviewUrl}
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
  )
}

const styles = {
  border: '0.0625rem solid #9c9c9c',
  borderRadius: '0.25rem',
};



const AudioComp = ({ media }) => {
  const [isPlayingMedia, setIsPlayingMedia] = React.useState(true)
  const audiocontrl = React.useRef()
  const sound = new Audio(media)
  return (
    <div>
      <audio controls={false} autoplay style={{ width: "90px" }} ref={audiocontrl}>
        <source src={`${media}`} type="audio/mpeg" />
        <source src={`${media}`} type="audio/mp3" />
        <source src={`${media}`} type="audio/wav" />
        <source src={`${media}`} type="audio/ogg" />
        Your browser does not support the audio element.
      </audio>
      <div>
        <div onClick={() => {
          setIsPlayingMedia(!isPlayingMedia)
          if (isPlayingMedia)
            audiocontrl.current?.play()
          else
            audiocontrl.current?.pause()
        }}>
          {!isPlayingMedia ?
            <i className="fa fa-pause" style={{ fontSize: "13px" }}></i>
            :
            <i className="fa fa-play " style={{ fontSize: "13px" }}></i>
          }
        </div>
      </div>
    </div>
  )
}